import "../sass/main.scss";

import "slick-carousel/slick/slick";

import "./script-modal";


import {initialiseLoadMore} from "./load-more";

jQuery(document).ajaxComplete(function () {
    initialiseMain();
});

let pattern_row = '<svg preserveAspectRatio="none" viewBox="0 0 1920 87" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0.359112L1920 84L1920 85.0039V87H0V84.9939V84H1659.58C1661.04 83.9444 1662.68 83.8901 1664.55 83.8373C1676.5 83.5 1700.5 79 1700.5 79C1700.5 79 1773.57 67.624 1803.29 41.8555C1839.39 10.5602 1880.25 -2.38926 1920 0.359112Z" fill="currentColor"/></svg>';
let pattern_row_before = '<svg preserveAspectRatio="none" viewBox="0 0 1920 87" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0.359131L1920 84L1920 85.0039V87H0V84.9939V84H1659.58C1661.04 83.9444 1662.68 83.8901 1664.55 83.8373C1676.5 83.5 1700.5 79 1700.5 79C1700.5 79 1773.57 67.624 1803.29 41.8555C1839.39 10.5602 1880.25 -2.38924 1920 0.359131Z" fill="currentColor"/></svg>';
let pattern_row_after = false;

jQuery(window).resize(function () {
    stickToSides();
    stickyTop();
});

jQuery(window).scroll(function () {
    stickyTop();
    // if (jQuery(this).scrollTop() > 50) {
    //     jQuery('body').addClass('scrolled');
    // } else {
    //     jQuery('body').removeClass('scrolled');
    // }
});

jQuery(document).ready(function () {
    initialiseMain();
    initialiseLoadMore();
    stickToSides();
    addTitleOnPageLoad();
    addPattern();
    stickyTop();

    jQuery('input[type=radio]').after('<span>');
    jQuery('input[type=checkbox]').after('<span>');

    jQuery('.frm_checkbox > span, .frm_radio > span').click(function () {
        jQuery(this).prev('input').click();
    });

    jQuery('.slick').slick();

    // let container = jQuery(document).width();

    // if (container >= 1280) {
    //   jQuery('.sc_slideshow_manual').each(function () {
    //     let content = jQuery(this).find('.sc_slideshow_manual__content');
    //
    //     jQuery(this).find('.sc_slideshow_manual__element__main').css({'margin-top': (content.height() + 70) + 'px'});
    //     jQuery(this).find('.slick').css({'margin-top': -content.height() + 'px'});
    //   });
    // }

    // Détection des colonnes avec image style 1 pour le style de la colonne
    let wpbColumns = document.querySelectorAll('.wpb_column');
    wpbColumns.forEach(function (column) {
        if (column.querySelector('.image-style-1')) {
            column.classList.add('column-image-style-1');
        }
    });

    // Ajout classe pour gérer la bannière texte au centre
    // document.querySelectorAll('.banner-thumbnail--disabled .banner__content').forEach(function(el) {
    //   var h1Element = el.querySelector('.h1, h1');
    //   if (h1Element && !h1Element.nextElementSibling) {
    //     el.classList.add('banner-text-center');
    //     document.querySelectorAll('.banner-thumbnail--disabled .breadcrumb').forEach(function(el) {
    //       el.classList.add('breadcrumb-center');
    //     });
    //   }
    // });


    // Fonction pour filtrer les références
    jQuery('.js-reference_terms_filter').click(function () {
        jQuery(this).addClass('loading');

        let selectedOptionValue = jQuery('.js-reference_terms:selected').val();

        jQuery('.js-reference_terms').removeClass('js-reference_terms--active');
        let term_name = jQuery('.js-reference_terms:selected').attr('data-name');

        let data = {
            'action': 'references_filters',
            'term_slug': selectedOptionValue,
        };

        jQuery.ajax({
            url: load_more_params_child.ajax_url,
            data: data,
            type: 'POST',
            success: function (data) {
                jQuery('.page-reference__terms-title').remove();
                if (term_name !== undefined) {
                    jQuery('<div class="page-reference__terms-title"><span class="h1 color-secondary">' + term_name + '</span></div>').insertBefore('#js-references-grid');
                }
                jQuery('#js-references-grid').html(data);

                // Modifier les liens de pagination
                jQuery('.pagination a').each(function () {
                    var currentHref = jQuery(this).attr('href');
                    var domaine = window.location.origin;
                    var newHref = currentHref.replace(domaine + '/wp-admin/admin-ajax.php', domaine + '/nos-realisations');


                    // Ajoutez le terme du filtre actuel comme paramètre d'URL
                    if (selectedOptionValue !== undefined) {
                        newHref += '?filter=' + selectedOptionValue;
                    }

                    jQuery(this).attr('href', newHref);
                });

                initialiseLoadMore();
            },
            complete: function () {
                jQuery('.js-reference_terms_filter').removeClass('loading');
            }
        });
    });

    var urlParams = new URLSearchParams(window.location.search);
    var filter = urlParams.get('filter');

    // Si un filtre est défini : l'afficher dans le select
    if (filter) {
        jQuery('.js-reference_terms[value="' + filter + '"]').prop('selected', true);
    }


    //fonction pour changer l'url lors du changement de page quand on a un filtre
    var paginationLinks = document.querySelectorAll('.pagination .page-number');
    paginationLinks.forEach(function (link) {
        link.addEventListener('click', function (e) {
            e.preventDefault();

            var currentPage = window.location.href;
            var newPage = this.getAttribute('href');

            var newUrl = newPage.replace(window.location.origin + '/wp-admin/admin-ajax.php', currentPage);
            window.location.href = newUrl;
        });
    });

    // Ajoute l'ancre #cards pour la pagination sauf la page avec le filtre : cause fonctionnement URL pour pagination
    if (!document.body.classList.contains('page-template-page-references')) {
        var paginationLinks = document.querySelectorAll('.pagination .page-number');
        paginationLinks.forEach(function (link) {
            link.href += '#cards';
        });
    }


    // Actions pour megamenu et placement du fond gris
    let $stickyHeader = jQuery('.sticky-header');
    let initialHeight = $stickyHeader.outerHeight();
    let adminBarHeight = jQuery('#wpadminbar').length ? 32 : 0;
    const body = document.body;
    const headerNav = document.querySelector('#header__nav');
    const isSinglePost = body.classList.contains('single-post');

    let topOffset;

    if (isSinglePost && headerNav) {
        headerNav.addEventListener('mousemove', () => {
            const visibleMegaMenu = Array.from(document.querySelectorAll('.mega-menu'))
                .find(menu => getComputedStyle(menu).display === 'block');

            if (visibleMegaMenu) {
                const megaMenuHeight = visibleMegaMenu.offsetHeight;
                topOffset = initialHeight + adminBarHeight + megaMenuHeight;
            } else {
                topOffset = initialHeight + adminBarHeight;
            }

            // Mettre à jour le style dynamique
            updateStyle(topOffset);
        });
    } else {
        topOffset = initialHeight + adminBarHeight;
        updateStyle(topOffset);
    }

// Fonction pour mettre à jour le style dynamique
    function updateStyle(offset) {
        let style = document.createElement('style');
        style.innerHTML = `
          body.mega-menu-active .site-content:after,
          body.mega-menu-active .header__banner:after {
            top: ${offset}px !important;
          }
        `;
        document.head.appendChild(style);
    }

    // function closeMegaMenu() {
    //     jQuery('body').removeClass('mega-menu-active');
    // }

    jQuery('.mega-menu').on('mouseleave', function () {
        closeMegaMenu();
    });

    //Megamenu click
    function megaMenuClean() {
        jQuery('.mega-menu').stop().fadeOut();
        jQuery('body').removeClass('mega-menu-active');
        jQuery('.menu-item').removeClass('mega-menu-open');
    }

    jQuery('.has-mega-menu').on('click', function (e) {
        e.preventDefault();

        megaMenuClean();

        let body = jQuery('body');
        let menu_id = jQuery(this).attr('id');

        if (jQuery(this).hasClass('mega-menu-open')) {
            jQuery(this).removeClass('mega-menu-open');
        } else {
            jQuery(this).addClass('mega-menu-open');

            let mega_block = jQuery('#mega-' + menu_id);
            let menu_position = jQuery(this).position();
            let menu_width = jQuery(this).width();
            let position_left = menu_position.left + (menu_width / 2) - 30;
            mega_block.find('.mega-menu__before').css('left', position_left + 'px');
            mega_block.stop().fadeIn();
        }

        if (body.hasClass('mega-menu-active')) {
            body.removeClass('mega-menu-active');
        } else {
            body.addClass('mega-menu-active');
        }

        return false;
    });

    document.addEventListener(
        "click",
        function (event) {
            if (!event.target.closest(".mega-menu") && !event.target.closest(".has-mega-menu")) {
                megaMenuClean();
            }
        },
        false
    );
    //Fin megamenu click

    if (jQuery('.slider').length) {
        jQuery('.slider .cards').slick({
            prevArrow: jQuery('.slick-prev'),
            nextArrow: jQuery('.slick-next'),
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
    }

    // Ajout de la flèche sur les liens du menu principal ayant un mega-menu
    const svgElement = `<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="shape" d="M1 1L6 6L11 1" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>`;
    const listItems = document.querySelectorAll('li.has-mega-menu');

    listItems.forEach(li => {
        const links = li.querySelectorAll('a');

        links.forEach(link => {
            link.insertAdjacentHTML('beforeend', svgElement);
        });
    });


    // Ajout de la flèche sur les liens du menu principal ayant un mega-menu (BURGER)
    const menuItems = document.querySelectorAll('.has-children-menu');

    menuItems.forEach(li => {
        const linkMenu = li.querySelectorAll('span.menu-item-href');

        linkMenu.forEach(linkMenu => {
            linkMenu.insertAdjacentHTML('beforeend', svgElement);
        });
    });

    document.querySelector('.menu-btn').addEventListener('click', function () {
        this.classList.toggle('active');
    });

});

function stickyTop() {
    let $stickyContainer = jQuery('.sticky-header');
    let $window = jQuery(window);
    let $body = jQuery('body');
    let $docViewTop = $window.scrollTop();
    let $padding = 0;

    /*if( $window.width() < 992 ){
        $body.css('padding-top','');
        $body.removeClass('js-sticky-on');
        $stickyContainer.removeClass('sticky--scrolled');
        return false;
    }*/

    if ($stickyContainer.length > 0) {
        if (!$body.is('.js-sticky-active')) {
            if (isInvisibleInWindow($stickyContainer, 100)) {
                // $stickyContainer.addClass('sticky--scrolled');
                $body.addClass('js-sticky-active');
                $stickyContainer.attr('data-initial-height', $stickyContainer.outerHeight());

                if ($body.is('.header__color-dark')) {
                    $body.addClass('js-header-switch-color');
                }
            }
        }

        if ($docViewTop > 0 && $body.is('.js-sticky-active')) {
            $body.addClass('js-sticky-on');

            //if( !$body.is('.header_template_template1') ){
            $padding = $stickyContainer.attr('data-initial-height');
            $body.css('padding-top', $padding);
            //}

            if ($body.is('.js-header-switch-color')) {
                $body.removeClass('header__color-dark');
            }
        } else if ($body.is('.js-sticky-active')) {
            $body.removeClass('js-sticky-on');

            if ($body.is('.js-header-switch-color')) {
                $body.addClass('header__color-dark');
            }

            //if( !$body.is('.header_template_template1') ){
            $padding = $stickyContainer.attr('data-initial-height');
            // $body.css('padding-top',$padding + 'px');
            //}
        }
    }
}

function isInvisibleInWindow(elem, offset = 0) {
    let $elem = jQuery(elem);
    let $window = jQuery(window);

    let docViewTop = $window.scrollTop();
    let docViewBottom = docViewTop + $window.height();

    let elemTop = $elem.offset().top;
    let elemBottom = elemTop + $elem.height();

    return ((elemTop >= docViewBottom) || ((elemBottom + offset) <= docViewTop));
}

// Fonction pour ajouter le titre si changement de pagination
function addTitleOnPageLoad() {
    var urlParams = new URLSearchParams(window.location.search);
    var filter = urlParams.get('filter');

    // Si un filtre est défini : ajouter le titre
    if (filter) {
        var termName = jQuery('.js-reference_terms[value="' + filter + '"]').attr('data-name');
        if (termName !== undefined) {
            jQuery('<div class="page-reference__terms-title"><span class="h1 color-secondary">' + termName + '</span></div>').insertBefore('#js-references-grid');
        }
    }
}

function initialiseMain() {
    /*
     * Pouvoir cliquer sur l'ensemble d'une carte même avec la mollette de la souris
     */
    jQuery('.card').on('mousedown', function (event) {
        const link = jQuery(this).find('.js-button').attr('href');
        if (!link) return;

        if (event.button === 0) {
            window.location.href = link;
        } else if (event.button === 1) {
            event.preventDefault();
            window.open(link);
        }
    });
}

function stickToSides() {
    let maxWidth = 2300;
    let $window = jQuery(window);

    jQuery('.stickToLeft').each(function () {
        let offset = jQuery(this).offset().left;
        let width = jQuery(this).width();
        var left = offset;

        if ($window.width() < maxWidth)
            jQuery(this).css('margin-left', -offset);
        else
            jQuery(this).css('margin-left', '');

        jQuery(this).width(width + left);
    });

    jQuery('.stickToRight').each(function () {
        let offset = jQuery(this).offset().left;
        let width = jQuery(this).width();
        var right = $window.width() - (offset + width)

        if ($window.width() < maxWidth)
            jQuery(this).css('margin-right', -right);
        else
            jQuery(this).css('margin-right', '');

        jQuery(this).width(width + right);
    });
}

function addPattern() {
    jQuery("#page-content > .container > .wpb-content-wrapper > .vc_row[data-vc-full-width='true']").each(function (index) {
        if (index != 0) {
            let $element = jQuery(this);

            // current bg
            let $current_bg = 'default';
            let $element_class = $element.attr('class').split(' ');
            for (let i = 0; i < $element_class.length; i++) {
                if ($element_class[i].slice(0, 9) === 'color-background-') {
                    $current_bg = $element_class[i].slice(9);
                }
            }

            // prev bg
            let $prev_bg = 'default';
            let $prevRow = $element.prevAll('.vc_row:not(.vc_clearfix)').first();
            let $prev_class = $prevRow.attr('class').split(' ');
            let $prev_pattern = 'left';

            for (let i = 0; i < $prev_class.length; i++) {
                if ($prev_class[i].slice(0, 9) === 'bg-color-') {
                    $prev_bg = $prev_class[i].slice(9);
                }
                if ($prev_class[i].slice(0, 20) === 'pattern-before-right') {
                    $prev_pattern = 'right';
                }
            }


            if ($current_bg != $prev_bg) {
                $element.addClass('pattern-before');

                if ($prev_bg != 'default')
                    $element.addClass('pattern-before-under-color-' + $prev_bg);


                //if( index%2 == 0 ){
                if ($prev_pattern == 'left') {
                    $element.addClass('pattern-before-right');
                }
            }

        }
    });

    jQuery('.pattern-before:not(.pattern-before-is-loaded)').each(function () {
        if (!pattern_row_before) return false;

        let $element = jQuery(this);
        let pattern_use = pattern_row_before;

        let elt_class = jQuery(this).attr('class').split(' ');
        let pattern_color = 'color-background';
        let pattern_bg_color = '';
        for (let i = 0; i < elt_class.length; i++) {
            if (elt_class[i].slice(0, 8) === 'bg-color') {
                pattern_color = 'block-pattern-before-color-' + elt_class[i].slice(9);
            }

            if (elt_class[i].slice(0, 26) === 'pattern-before-under-color') {
                pattern_bg_color = 'bg-color-' + elt_class[i].slice(27);
            }
        }

        let block = jQuery('<div class="block-pattern block-pattern-before ' + pattern_color + ' ' + pattern_bg_color + '">' + pattern_use + '</div>').prependTo(jQuery(this));
        let height = block.height();

        jQuery(this)
            .css('overflow', 'visible')
            .css('position', 'relative');

        if (!jQuery(this).hasClass('pattern-before-no-margin'))
            jQuery(this).css('margin-top', height);

        jQuery(this).addClass('pattern-before-is-loaded');

        patternBgColor(jQuery(this), block);
    });

    //FOOTER
    jQuery('.pattern-before-footer:not(.pattern-before-footer-is-loaded)').each(function () {
        if (!pattern_row_before) return false;

        let $element = jQuery(this);
        let pattern_use = pattern_row_before;

        //Détection dernière rangée de main pour la couleur
        let footer_row = jQuery('main .wpb-content-wrapper .vc_row').last();
        let color_class = footer_row.attr('class').split(' ').find(c => c.startsWith('color-'));

        let elt_class = jQuery(this).attr('class').split(' ');
        let pattern_color = 'color-white';
        let pattern_bg_color = color_class;
        for (let i = 0; i < elt_class.length; i++) {
            if (elt_class[i].slice(0, 8) === 'bg-color') {
                pattern_color = 'block-pattern-before-footer-color-' + elt_class[i].slice(9);
            }

            if (elt_class[i].slice(0, 26) === 'pattern-before-footer-under-color') {
                pattern_bg_color = 'bg-color-' + elt_class[i].slice(27);
            }
        }

        let block = jQuery('<div class="block-pattern block-pattern-before-footer ' + pattern_color + ' ' + pattern_bg_color + '">' + pattern_use + '</div>').prependTo(jQuery(this));
        let height = block.height();

        jQuery(this)
            .css('overflow', 'visible')
            .css('position', 'relative');

        if (!jQuery(this).hasClass('pattern-before-no-margin'))
            jQuery(this).css('margin-top', height);

        jQuery(this).addClass('pattern-before-footer-is-loaded');

        // patternBgColor(jQuery(this), block);
    });

    jQuery('.pattern-before-secondary:not(.pattern-before-secondary-is-loaded)').each(function () {
        if (!pattern_row_before) return false;

        let $element = jQuery(this);
        let pattern_use = pattern_row_before;

        // Détection de la rangée parente pour la couleur
        let block_parent_pattern = jQuery('.pattern-before-secondary');
        let parent_block = block_parent_pattern.prevAll('.vc_row').first();

        var $body = jQuery('body');

        // si parent_block est vide on met color_class="" :
        if (parent_block.length === 0) {
            var color_class = '';
        } else {
            var color_class = parent_block.attr('class').split(' ').find(c => c.startsWith('color-'));
        }

        if ($body.hasClass('single-reference')) {
            var color_class = 'color-background';
        }

        let elt_class = jQuery(this).attr('class').split(' ');
        let pattern_color = '';
        let pattern_bg_color = color_class;
        for (let i = 0; i < elt_class.length; i++) {
            if (elt_class[i].slice(0, 8) === 'bg-color') {
                pattern_color = 'block-pattern-before-secondary-color-' + elt_class[i].slice(9);
            }

            if (elt_class[i].slice(0, 26) === 'pattern-before-secondary-under-color') {
                pattern_bg_color = 'bg-color-' + elt_class[i].slice(27);
            }
        }

        let block = jQuery('<div class="block-pattern block-pattern-before-secondary ' + pattern_color + ' ' + pattern_bg_color + '">' + pattern_use + '</div>').prependTo(jQuery(this));
        let height = block.height();

        jQuery(this)
            .css('overflow', 'visible')
            .css('position', 'relative');

        if (!jQuery(this).hasClass('pattern-before-no-margin'))
            jQuery(this).css('margin-top', height);

        jQuery(this).addClass('pattern-before-secondary-is-loaded');

        patternBgColor(jQuery(this), block);
    });

    jQuery('.pattern-after:not(.pattern-after-is-loaded)').each(function () {
        if (!pattern_row_after) return false;

        let pattern_use = pattern_row_after;

        let elt_class = jQuery(this).attr('class').split(' ');
        let pattern_color = 'color-primary';
        let pattern_bg_color = '';
        for (let i = 0; i < elt_class.length; i++) {
            if (elt_class[i].slice(0, 19) === 'pattern-after-color') {
                pattern_color = elt_class[i].slice(14);
            }

            if (elt_class[i].slice(0, 22) === 'pattern-after-bg-color') {
                pattern_bg_color = elt_class[i].slice(14);
            }
        }

        let block = jQuery('<div class="block-pattern block-pattern-after ' + pattern_color + ' ' + pattern_bg_color + '">' + pattern_use + '</div>').appendTo(jQuery(this));
        let height = block.height();

        jQuery(this)
            .css('overflow', 'visible')
            .css('position', 'relative');

        if (!jQuery(this).hasClass('pattern-after-no-margin'))
            jQuery(this).css('margin-bottom', height);

        jQuery(this).addClass('pattern-after-is-loaded');

        patternBgColor(jQuery(this), block);
    });

}

function patternBgColor(parent, block) {
    if (jQuery(parent).not('.color-background-primary, .color-background-secondary')) {
        block.addClass('color-white');
    } else if (jQuery(parent).hasClass('color-background-primary')) {
        block.addClass('color-background-primary');
        console.log('color-background-primary');
    } else if (jQuery(parent).hasClass('color-background-secondary')) {
        block.addClass('color-background-secondary');
        console.log('color-background-secondary');
    } else if (jQuery(parent).hasClass('bg-color-tertiary')) {
        block.addClass('color-tertiary');
    } else if (jQuery(parent).hasClass('color-background')) {
        console.log('color-background');
        block.addClass('color-background');
    } else if (jQuery(parent).hasClass('bg-color-white')) {
        block.addClass('color-white');
        console.log('color-white');
    } else {
        block.addClass('color-white');
    }
}


document.addEventListener('DOMContentLoaded', () => {
    // Menu burger - ouvrir les sous menus
    const menuButton = document.querySelector('#active_header__menu');
    const menu = document.querySelector('.menu');

    if (menuButton && menu) {
        menuButton.addEventListener('click', () => {
            menuButton.classList.toggle('active');
            menu.classList.toggle('open');
        });
    }

    // Ajout classe pour le bouton menu burger
    const menuParents = document.querySelectorAll('.menu-item-has-children');
    menuParents.forEach(parent => {
        const toggleButton = parent.querySelector('.menu-item-href');

        if (toggleButton) {
            toggleButton.addEventListener('click', (event) => {
                event.preventDefault();
                parent.classList.toggle('open');
            });
        }
    });

    // No margin & padding pour composant profession list
    document.querySelectorAll('.sc_page_list_simple_profession').forEach(professionElement => {
        const wpbWrapper = professionElement.closest('.wpb_wrapper');

        if (wpbWrapper) {
            const vcColumnInner = wpbWrapper.closest('.vc_column-inner');

            if (vcColumnInner) {
                vcColumnInner.style.paddingLeft = '0';
                vcColumnInner.style.paddingRight = '0';
            }
        }
    });

});
